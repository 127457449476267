.Border{
    padding-top: 34px;
    transform: translateY(-25px);
    margin-bottom: -15px;
    padding-bottom: 2px;
}

.loginHeadings{
    text-align: center;
}

.Border p{
    text-align: left;
    margin-left: 3%;
    margin-right: 3%;
}

.loginHeadings h1{
    margin-bottom: 15px;
}