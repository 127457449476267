.middle{
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    transform: translateZ(2.5px);
    /* transform: translateX(-100px); */
}

.switch{
    position: relative;
    display: inline-block;
    width: 180px;
    height: 50px;
    border-radius: 30px;
    background-color: rgb(231, 230, 230);
}


.switch input{
    opacity: 0;
    width: 0;
    height: 0;
}

.slider{
    position: absolute;
    cursor: pointer;

    top: 2.5px;
    left: 2.5px;
    right: 2.5px;
    bottom: 2.5px;

    
    border-radius: 30px;
    /* background-color: rgb(237, 236, 236); */
    background-color: rgba(244, 194, 156, 0.205);
}


.slider:before{
    position: absolute;
    content: "Phone";
    padding-top: 9px;
    text-align: center;
    /* color: black; */
    color: rgba(252, 249, 230, 0.957);
    font-weight: 550;
    font-size: 14px;
    height: 36px;
    width: 90px;
    left: 0px;
    bottom: 2.5px;
    top: 0px;
    right: 2.5px;
    background-color: #da4040;
    border-radius: 30px;
    transition: 0.2s;
}

.slider:after{
    position: absolute;
    content: "Shera ID";
    color: rgb(69, 69, 69);
    font-weight: 450;
    padding-top: 9px;
    text-align: center;
    font-size: 14px;
    height: 36px;
    width: 90px;
    left: 85px;
    bottom: 2.5px;
    top: 0px;
    right: 2.5px;
    border-radius: 30px;
}


input:checked + .slider:before{
    transform: translateX(85px);
    content: 'Shera ID';
}

input:checked + .slider:after{
    transform: translateX(-85px);
    content: 'Phone';
}





